import React, { FC } from "react";
import Content from "./Content";
import { Message } from "semantic-ui-react";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

const BookingView: FC = () => {
  return (<><BrowserView><Content /></BrowserView>
    <MobileView>
      <Content />
      {/*<Message>Varauskartta ei toimi mobiilissa, sry.. (nettisivuvelho hakusessa)</Message>*/}
    </MobileView></>);
};

export default BookingView;
