import React, { FC } from "react";
import Table from "./Table";

interface TableGroupProps {
  name: string;
  booked: string[];
  booking: string;
  toggle: (location: string) => void;
  extra?: boolean;
  disabled?: boolean;
  groupSize?: number;
  groupType?: number;
  style?: object;
  rotate?: boolean;
  startOffset?: number;
}


const TableGroup: FC<TableGroupProps> = (props: TableGroupProps) => {
  const labelClass = "table-group-label";
  let tableGroupClass = " table-group-normal";
  let tableGroupType = "PERUSPAIKKA";

  let tables = [1, 2, 3, 4];
  let flipTables = [5, 6, 7, 8];
  switch (props.groupSize) {
    case 4:
      tableGroupClass = " table-group-4";
      tables = [1, 2];
      flipTables = [3, 4];
      break;
    case 42:
      tableGroupClass = " table-group-4-2";
      tables = [1, 2, 3, 4];
      flipTables = [];
      break;
    case 5:
      tableGroupClass = " table-group-5";
      tables = [4, 5];
      flipTables = [1, 2, 3];
      break;
    case 52:
      tableGroupClass = " table-group-5-2";
      tables = [1, 2, 3, 4, 5];
      flipTables = [];
      break;
    case 6:
      tableGroupClass = " table-group-6";
      tables = [1, 2, 3];
      flipTables = [4, 5, 6];
      break;
    case 62:
      tableGroupClass = " table-group-6-2";
      tables = [1, 2, 3, 4, 5, 6];
      flipTables = [];
      break;
    case 82:
      tableGroupClass = " table-group-8-2";
      tables = [1, 2, 3, 4, 5, 6, 7, 8];
      flipTables = [];
      break;
    case 9:
      tableGroupClass = " table-group-9";
      tables = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      break;
    case 10:
      tableGroupClass = " table-group-10";
      tables = [1, 2, 3, 4, 5];
      flipTables = [6, 7, 8, 9, 10];
      break;
    case 12:
      tableGroupClass = " table-group-12";
      tables = [1, 2, 3, 4, 5, 6];
      flipTables = [7, 8, 9, 10, 11, 12];
      break;
    case 14:
      tableGroupClass = " table-group-extra";
      tables = [1, 2, 3, 4, 5, 6, 7];
      flipTables = [8, 9, 10, 11, 12, 13, 14];
      break;
    case 16:
      tableGroupClass = " table-group-extra";
      tables = [1, 2, 3, 4, 5, 6, 7, 8];
      flipTables = [9, 10, 11, 12, 13, 14, 15, 16];
      break;
    case 18:
      tableGroupClass = " table-group-18";
      tables = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      flipTables = [10, 11, 12, 13, 14, 15, 16, 17, 18];
      break;
    case 24:
      tableGroupClass = " table-group-24";
      tables = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      flipTables = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
      break;
  }
  switch (props.groupType) {
    case 1:
      tableGroupClass += " table-group-premium";
      tableGroupType = "SIUNATTU PAIKKA";
      break;
  }
  if (props.rotate) {
    tableGroupClass += " table-group-rotate";
  }
  return (
    <div className={"table-group" + tableGroupClass} style={props.style}>
      {/*<h3 className={'table-group-header'}>{tableGroupType}</h3>*/}
      <div className={"table-group-content"}>
        <div className={"table-group-row"}>
          {tables.map(item => (
            <Table
              key={item + (props.startOffset ? props.startOffset : 0)}
              location={props.name + (item + (props.startOffset ? props.startOffset : 0))}
              booked={props.booked}
              booking={props.booking}
              toggle={props.toggle}
              disabled={props.disabled}
            />
          ))}
        </div>
        <div>
          {flipTables.map(item => (
            <Table
              key={item + (props.startOffset ? props.startOffset : 0)}
              flip
              location={props.name + (item + (props.startOffset ? props.startOffset : 0))}
              booked={props.booked}
              booking={props.booking}
              toggle={props.toggle}
              disabled={props.disabled}
              rotate={flipTables.length !== tables.length && (item == flipTables.length)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableGroup;
