import React from 'react';
import tila from 'assets/lanitila.jpg';
import ruokapoyta from 'assets/ruokapoyta.jpg';
import paidat from 'assets/staff-paidat.jpg';
import { Container, Image, Segment} from 'semantic-ui-react';
import Subtitle from './Subtitle';

const InfoView: React.FC = () => {
    return (
        <>
            <Container text>
                <Image src={tila} size="big" alt="Lani tila" centered rounded/>
                <Segment color="orange">
                    <Subtitle size="big" title="Tietoja Laneista"/>
                    <fieldset>
                        <legend>Sisällys</legend>
                        <ul>
                            <li>
                                <a href="#taustaa">Alustus</a>
                            </li>
                            <li>
                                <a href="#tapahtumapaikka">Tapahtumapaikka</a>
                            </li>
                            <li>
                                <a href="#ilmoittautuminen">Ilmoittautuminen</a>
                            </li>
                            <li>
                                <a href="#yleista">Yleistä tietoa</a>
                            </li>
                            <li>
                                <a href="#maksut">Maksut</a>
                            </li>
                            <li>
                                <a href="#mitamukaan">Mitä tarvitaan mukaan?</a>
                            </li>
                            <li>
                                <a href="#poytatila">Pöytätila</a>
                            </li>
                            <li>
                                <a href="#tournaments">Turnaukset</a>
                            </li>
                            <li>
                                <a href="#ohjelma">Ohjelma</a>
                            </li>
                            <li>
                                <a href="#yhteystiedot">Yhteystiedot</a>
                            </li>
                        </ul>
                    </fieldset>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="taustaa" title="Taustaa"/>
                    <p>
                        <b>
                            Vuosi 2025 on lähellä ja se tarkoittaa vuotuista tammikuun lanitapahtumaa. Tänäkin vuonna
                            kokoonnumme Porin Helluntaikirkon alasaliin Itsenäisyydenkatu 9:ssä. Tammilanit alkaa perjantaina
                            17.1. klo 15 ja loppuvat sunnuntaina 19.1. klo 12. Tervetuloa mukaan niin uudet gamerit kuin
                            paatuneemmatkin lanirotat. Nähdään Tammilaneilla! </b>
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="tapahtumapaikka" title="Tapahtumapaikka"/>
                    <p>Tapahtumapaikka on Porin helluntaikirkko, osoitteessa Itsenäisyydenkatu 9, 28100 Pori.</p>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="ilmoittautuminen" title="Ilmoittautuminen"/>
                    <p>
                        Laneille tulee ilmoittautua etukäteen{' '}
                        <a href={'https://tammilan.it/registration'}>ilmoittautumislomakkeella</a>. Näin tiedämme varata
                        pöytätilaa kaikille. Mukaan mahtuu 62 ensin ilmoittautunutta. Ilmoittautumisen
                        jälkeen saat sähköpostilla tunnukset paikanvaraukseen.
                    </p>
                    <p>
                        Pöytäpaikan voi varata etukäteen, jos vaikkapa haluaa varmistaa kaverinsa kanssa vierekkäiset
                        paikat. Pöytävaraustilanne näkyy
                        <a href={'https://tammilan.it/booking'}> varaussivulla</a>.
                    </p>
                    <p>Alle 13-vuotiailta tarvitsemme huoltajan tiedot.</p>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="yleista" title="Yleistä tietoa"/>
                    <p>
                        Alueella on useita erillisiä tiloja pelaajille sekä yöpyjille. Paikalta löytyy jääkaappi
                        eväille, uuni/mikro eväiden lämmittämiseen sekä kahvinkeitin riippuvaisille. Lanit alkavat
                        perjantaina klo 15 mutta mahdollisuuksien mukaan oman koneen voi tuoda paikalle jo torstaina.
                        Pöydät ja kova tuoli löytyy paikan päältä. Oman tuolin voi tuoda tullessaan. Yöpymistilat
                        löytyvät kirkon alasalin läheisistä huoneista. Paikan päällä voidaan tehdä yhteisiä
                        ruokatilauksia.
                    </p>
                    <p>
                        Muista liittyä <a href={'https://discord.gg/89dss6q5Vz'} target={"_blank"} rel={"noreferrer"}>Tammilanit Discordiin</a>. Laneihin
                        pääset ilmoittautumaan täyttämällä{' '}
                        <a href={'https://tammilan.it/registration'}>ilmoittautumislomakkeen</a>. Tapahtuma on
                        päihteetön.
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="maksut" title="Maksut"/>
                    <p>
                        Osallistumismaksu on paikkavalinnasta riippuen 15€/25€ jonka voi maksaa MobilePaylla numeroon
                        <br/>
                        <b>+358 45 346 2128</b> tai tilinumeroon <b>FI41 1807 2961 1628 14</b> (Riku Kuusisto).
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="mitamukaan" title="Mitä tarvitaan mukaan?"/>
                    <ul>
                        <li>tietokone tai konsoli ja tarvittavat lisälaitteet ja johdot</li>
                        <li>sähköjatkojohto, meiltä saa yhden pistorasiapaikan</li>
                        <li>Verkkokaapeli, lähin kytkin 1-4 metrin päässä. (Kaapeleita myynnissä 10€/kpl paikan päällä)</li>
                        <li>Pelituoli (halutessasi)</li>
                        <li>Yöpymisvarusteet: patja, makuupussi/peitto jne.</li>
                        <li>Omat eväät</li>
                        <li>Rahaa ruokaan</li>
                    </ul>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="poytatila" title="Pöytätila"/>
                    <p>
                        Konepaikkoja on kahdenlaisia.
                        <ul>
                            <li>Peruspaikka 100x75cm 15€.</li>
                            <li>Siunattu paikka 120x80cm 25€.</li>
                        </ul>
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="tournaments" title="Turnaukset"/>
                    <p>
                        Laneissa tullaan järjestämään mm. CS 2-, ja Tekken 3-turnaukset.<br/>
                        Ensi kertaa Tammilanien historiassa järjestääme CS 2 runkosarjapelit syystalvella.
                        Laneilla pääpaino annetaan CS 2 -turnauksen pudotuspeleille ja finaalille sekä lanien aikana
                        muodostuville pelisessioille.

                        Ilmoittautuminen turnauksiin tapahtuu vastaamalla{' '}
                        <a href={'https://forms.gle/rj76mteVCPCyLywV9'} target={"_blank"} rel={"noreferrer"}>Google Forms -lomakkeeseen</a>. Finaalit tullaan
                        streamaamaan Tammilanien omalla Twitch-kanavalla. Turnauksien sääntöihin pääset{' '}
                        <a href={'https://docs.google.com/document/d/1v46FhPN1gHMEBokuWSQ76CiLD-HZVpCg4hr8emWz3Gk/view'} target={"_blank"} rel={"noreferrer"}>tästä</a>
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="ohjelma" title="Ohjelma"/>
                    <p>
                        Huom! Kaikki aikataulut ovat suuntaa antavia. Life-illan ajaksi rauhoitamme lanitilan kokonaan.
                    </p>
                    <p>
                        <b>
                            <u>Perjantai 17.01.2025</u>
                        </b>
                    </p>
                    <p>
                        klo 15 Ovet
                        <br/>
                        klo 18 Aloitusinfot ja yleistä pelailua ***Mahdollinen ruokatilaus***
                        <br/>
                        klo 20 CS 2 -karsinnat
                        <br/>
                        klo 23 Tekken turnaus Ottelut 1-12
                    </p>
                    <p>
                        <b>
                            <u>Lauantai 18.01.2025</u>
                        </b>
                    </p>
                    <p>
                        klo 12 Aamun koomailut ***Mahdollinen ruokatilaus***
                        <br/>
                        klo 14 CS 2 -turnaus (välierät BO3)
                        <br/>
                        klo 17 Tekken turnaus Ottelut 13-24
                        <br/>
                        klo 18 Tekken semifinaali+finaali
                        <br/>
                        klo 19 <b>Life-nuortenilta yläsalissa</b>
                        <br/>
                        klo 20 Päivällinen (vapaaehtoinen maksu)
                        <br/>
                        klo 21 CS 2 Finaali (BO3)
                    </p>
                    <p>
                        <b>
                            <u>Sunnuntai 19.01.2025</u>
                        </b>
                    </p>
                    <p>
                        klo 08 Aamun koomailut
                        <br/>
                        klo 8-10 Vapaata pelailua
                        <br/>
                        klo 10 Siivous alkaa
                        <br/>
                        klo 12 <b>PURKU!</b>
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle id="yhteystiedot" title="Yhteystiedot"/>

                    <p>Tapahtuman järjestää Tammilanit-yhteisö.</p>
                    <p>
                        Tapahtuman koordinaattori
                        <br/>
                        Pekka Peltomäki
                        <br/>
                        +358 40 081 1178
                        <br/>
                        pekkapeltomaki (at) msn.com
                    </p>
                    <p>
                        Tekninen suunnittelu ja tuki
                        <br/>
                        Riku Kuusisto
                        <br/>
                        +358 45 346 2128
                        <br/>
                        rikse94@gmail.com
                    </p>
                </Segment>
            </Container>
        </>
    );
};

export default InfoView;
