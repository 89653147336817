import React, { FC } from 'react';
import InfoBanner from './InfoBanner';
import Subtitle from 'components/Subtitle';
import { Label } from 'semantic-ui-react';
import config from 'config/config';

const style = {
    infolabel: { marginBottom: 20 },
    label: { width: 100 }
};

interface InfoLabelProps {
    label: string;
    message: string;
}

const InfoLabel: FC<InfoLabelProps> = ({ label, message }: InfoLabelProps) => {
    return (
        <>
            <div style={style.infolabel}>
                <Label ribbon style={style.label}>
                    {label}
                </Label>
                {message}
            </div>
        </>
    );
};

const BasicInfo: FC = () => {
    return (
        <>
            <InfoBanner />
            <Subtitle title="Perustietoa" />
            <InfoLabel label="Ajankohta" message={config.EVENT_TIME} />
            <InfoLabel label="Osoite" message="Itsenäisyydenkatu 9, 28100 Pori" />
            <InfoLabel label="Lisätietoja" message="040 081 1178 / pekkapeltomaki@msn.com" />
            <InfoLabel label="Järjestäjä" message="Tammilanit-yhteisö" />

            <p>
                Tällä lomakkeella ilmoittaudut Tammilaneihin vuosimallia 2025! Lanit alkavat perjantaina 17.1.2025 klo 15
                ja päättyy sunnuntaina 19.1 klo 12.
            </p>
            <p>
                Järjestämme CS 2 sekä Tekken 3 -turnaukset. Ilmoita meille myös turnausilmoittautumisessa jos haluat
                järjestää oman turnauksen haluamassasi pelissä!
            </p>
            <p>Tapahtuma on päihteetön</p>
            <p>
                Pelipaikkana toimii viimevuoden tapaan Porin Helluntaikirkon alasali. Konepaikkoja on kahdenlaisia.
                <ul>
                    <li>Peruspaikka 100x75cm 15€.</li>
                    <li>Siunattu paikka 120x80cm 25€.</li>
                </ul>
            </p>
            <p>Paikkamaksu maksetaan lani-infossa ilmoitetuun Grilliporon tilinumeroon/Mobilepayhin.</p>
            <p>Ilmoittautumisen jälkeen saat sähköpostiisi linkin josta voit varata konepaikan.</p>
        </>
    );
};

export default BasicInfo;
