import axios from 'axios';
import config from 'config/config';

class TableService {
    host = config.API_BASE_URL;
    apiPath = '/v1/lanbooking';

    book(email?: string, code?: string, location?: string) {
        if (email && code && location) {
            return axios.post(`${this.host}${this.apiPath}`, {
                email: email,
                code: code,
                location: location
            });
        }

        return Promise.reject('email, code or location missing');
    }

    free(email?: string, code?: string) {
        if (email && code) {
            return axios.post(`${this.host}${this.apiPath}`, {
                email: email,
                code: code,
                location: null
            });
        }

        return Promise.reject('email, code or location missing');
    }

    isBooked(location: string) {
        return axios.get(`${this.host}${this.apiPath}/isbooked/` + location);
    }

    allBooked() {
        return axios.get(`${this.host}${this.apiPath}/booked`);
    }

    myBooking(email: string, code: string) {
        const params = new URLSearchParams();
        params.append('email', email);
        params.append('code', code);

        return axios.get(`${this.host}${this.apiPath}/check`, { params: params });
    }

    sendEmails() {
        const code = 'lan123Send456Mail';
        const params = new URLSearchParams();
        params.append('code', code);
        return axios.get(`${this.host}${this.apiPath}/sendEmails`, { params: params });
    }
}

export default new TableService();
