// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: "Roboto", "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000 !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/index.scss","webpack://./src/assets/variables.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;AADJ;;AAIA;EACI,gBAAA;EACA,SAAA;EACA,8BCiBiB;EDhBjB,mCAAA;EACA,kCAAA;EACA,oCAAA;AADJ","sourcesContent":["@import 'assets/variables';\n\nhtml {\n    height: 100%;\n}\n\nbody {\n    min-height: 100%;\n    margin: 0;\n    font-family: $normal-font-family;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    background-color: #000000 !important;\n}\n","//$highlight-color: #532B72\n$background-color: #000000;\n$title-color-inverse: #e6dff9;\n\n$primary: #9a6fc7;\n$secondary: #00ffff;\n$darkgrey: black;\n\n$logo-color: $primary;\n$title-color: $primary;\n$subtitle-color: $secondary;\n$content-background: $darkgrey;\n\n// $background-light-color: #518fd4;\n// $background-light-color-hover: #619fe4;\n// $background-dark-color: #093b64 !important;\n// $background-dark-color-hover: #194b74;\n// $background-form-blue: #e2edff;\n// $background-form-blue-hover: #eff3ff;\n// $background-form-blue-selected: #bfd6fc;\n// $background-submenu-color: #597EAA;\n// $background-form: #efefef;\n\n$error-color: #bd4a33;\n$premium: darkgoldenrod;\n\n$normal-font-family: 'Roboto', 'Arial';\n$title-font-family: 'Roboto', 'Verdana';\n\n$scale-modifier: 0.714"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
