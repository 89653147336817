import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';
import Subtitle from 'components/Subtitle';
import FieldText from './FieldText';

const ContactInfo: FC = () => {
    return (
        <>
            <Subtitle title="Yhteystiedot" />
            <Grid columns={2} stretched stackable>
                <Grid.Column>
                    <FieldText name="etunimi" title="Etunimi" required />
                </Grid.Column>
                <Grid.Column>
                    <FieldText name="sukunimi" title="Sukunimi" required />
                </Grid.Column>
                <Grid.Column>
                    <FieldText name="nickname" title="Nimimerkki" required />
                </Grid.Column>
                <Grid.Column>
                    <FieldText name="steamId" title="SteamID" placeholder={'76561198005488744'} />
                </Grid.Column>
                <p>
                    SteamID:n selvität helposti vaikkapa osoitteessa{' '}
                    <a href={'https://www.steamidfinder.com/'} target={"_blank"} rel={"noreferrer"}>https://www.steamidfinder.com/</a>
                </p>
                <Grid.Row>
                    <Grid.Column>
                        <FieldText name="puhelin" title="Puhelinnumero" required />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <FieldText name="email" title="Sähköposti" required />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default ContactInfo;
