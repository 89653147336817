import config from 'config/config';
import React, { FC } from 'react';
import { Message } from 'semantic-ui-react';

const InfoBanner: FC = () => {
    return (
        <Message color="purple">
            <Message.Header className="message_header">Lanit tulossa taas!</Message.Header>
            <Message.Content className="message_content">
                <p>Seuraavat lanit on {config.EVENT_TIME}. Tervetuloa!</p>
                {/* <p>
                    <b>
                        Ethän tule paikalle, jos sinulla on flunssa oireita. Pidätämme oikeuden lähettää kotiin
                        sellaiset henkilöt jotka oireilevat tai sairastuvat lanien aikana.
                    </b>
                </p> */}
            </Message.Content>
        </Message>
    );
};

export default InfoBanner;
