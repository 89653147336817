import React, { CSSProperties, FC } from 'react';
import { ErrorMessage, Field, FieldInputProps } from 'formik';
import { Form } from 'semantic-ui-react';

interface FieldTextAreaProps {
    name: string;
    title: string;
    placeholder?: string;
    required?: boolean;
    size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive';
    style?: CSSProperties;
    disabled?: boolean;
}

const FieldTextArea: FC<FieldTextAreaProps> = ({
    name,
    title,
    placeholder,
    required,
    size,
    style,
    disabled
}: FieldTextAreaProps) => {
    return (
        <>
            <Field name={name}>
                {({ field }: { field: FieldInputProps<string> }) => (
                    <Form.Input
                        fluid
                        label={title + (required ? ' *' : '')}
                        placeholder={placeholder || title}
                        value={field.value || ''}
                        onChange={field.onChange}
                        id={field.name}
                        onBlur={field.onBlur}
                        style={style}
                        size={size}
                        disabled={disabled}
                        as={'textarea'}
                    />
                )}
            </Field>
            <ErrorMessage
                name={name}
                createRoot={(errorMessage: any) => <span className="required">{errorMessage}</span>}
            />
        </>
    );
};

export default FieldTextArea;
