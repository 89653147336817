import React, { FC, useCallback, useState } from 'react';
import { Button, Container, Header, Message, Segment } from 'semantic-ui-react';
import 'assets/Form.scss';
import ContactInfo from './ContactInfo';
import GuardianInfo from './GuardianInfo';
import TableReservation from './TableReservation';
import Tournaments from './Tournaments';
import AttendanceDays from './AttendanceDays';
import SleepingInfo from './SleepingInfo';
import BasicInfo from './BasicInfo';
import Food from './Food';
import { Form, Formik } from 'formik';
import { RegistrationDto } from 'types/common';
import * as Yup from 'yup';
import CountDownClock from 'components/CountDownClock';
import registrationService from 'services/registrationService';
import config from 'config/config';
import Feedback from './Feedback';

const RegistrationSchema = Yup.object().shape({
    etunimi: Yup.string().required('Pakollinen tieto'),
    sukunimi: Yup.string().required('Pakollinen tieto'),
    puhelin: Yup.string().required('Pakollinen tieto'),
    email: Yup.string().email('Virheellinen sähköposti').required('Pakollinen tieto')
});

const RegistrationView: FC = () => {
    const [showMessage, setShowMessage] = useState({ title: '', message: '', success: false });
    const initialValues: RegistrationDto = {
        etunimi: '',
        sukunimi: '',
        email: '',
        puhelin: ''
    };

    const storeRegistration = useCallback(async (values: RegistrationDto) => {
        try {
            const res = await registrationService.register(values);
            if (res.success) {
                setShowMessage({
                    title: 'Rekisteröinti onnistui!',
                    message: 'Sähköpostiisi on lähetetty pöytävarausohjeet.',
                    success: true
                });
            } else {
                setShowMessage({
                    title: 'Rekisteröinti epäonnistui!',
                    message: '',
                    success: false
                });
            }
        } catch (err: any) {
            if (err.response.data.message === 'already registered') {
                setShowMessage({
                    title: 'Rekisteröinti epäonnistui!',
                    message: 'Sähköpostilla on jo rekisteröity ilmoittautuminen.',
                    success: false
                });
            } else {
                setShowMessage({
                    title: 'Rekisteröinti epäonnistui!',
                    message: '',
                    success: false
                });
            }
        }
    }, []);

    if (config.REGISTRATION_OPEN !== 'true') {
        return (
            <Container text>
                <Segment raised>
                    <Header as="h1" textAlign="center" style={{ paddingTop: 3 }}>
                        Tammilaneille ilmoittautuminen on suljettu
                    </Header>
                </Segment>
                <Segment raised textAlign="center">
                    <Header as="h4">
                        Seuraavien lanien päivämäärää ei ole päätetty eikä ilmoittautuminen ole auki.
                        <br />
                        Seuraa Tammilanien viestikanavia...
                    </Header>
                </Segment>
            </Container>
        );
    }

    return (
        <Formik initialValues={initialValues} validationSchema={RegistrationSchema} onSubmit={storeRegistration}>
            {({ isSubmitting, errors }) => (
                <Form>
                    <Container text>
                        <Segment raised>
                            <Header as="h1" textAlign="center" style={{ paddingTop: 3 }}>
                                Tammilaneille {config.EVENT_TIME} ilmoittautuminen
                            </Header>
                        </Segment>
                        <Segment raised textAlign="center">
                            <CountDownClock />
                        </Segment>
                        <Segment raised>
                            <BasicInfo />
                        </Segment>
                        <Segment raised>
                            <SleepingInfo />
                        </Segment>
                        <Segment raised>
                            <ContactInfo />
                        </Segment>
                        <Segment raised>
                            <GuardianInfo />
                        </Segment>
                        <Segment raised>
                            <TableReservation />
                        </Segment>
                        <Segment raised>
                            <Food />
                        </Segment>
                        <Segment raised>
                            <Feedback />
                        </Segment>
                        {Object.keys(errors).length > 0 && (
                            <div className="required errorMessage">
                                Lomakkeella on virheitä korjaa ne ennen lähetystä!
                            </div>
                        )}
                        <Button
                            primary
                            size="big"
                            type="submit"
                            disabled={isSubmitting || Object.keys(errors).length > 0}
                        >
                            Lähetä
                        </Button>
                        <p>
                            <span style={{ color: 'grey' }}>* pakollinen tieto</span>
                        </p>
                        {showMessage.message.length > 0 && (
                            <Message style={{ zIndex: 1000 }} color={showMessage.success ? 'green' : 'red'}>
                                <Message.Header>{showMessage.title}</Message.Header>
                                <Message.Content>{showMessage.message}</Message.Content>
                            </Message>
                        )}
                    </Container>
                </Form>
            )}
        </Formik>
    );
};

export default RegistrationView;
