import React from 'react';
import config from 'config/config';

// https://www.timeanddate.com/clocks/freecountdown.html
const CountDownClock: React.FC<{ disabled?: boolean }> = ({ disabled }: { disabled?: boolean }) => {
    if (disabled) {
        return null;
    }

    return (
        <iframe
            title="CountDown Clock"
            style={{ width: 312, height: 62, margin: 20, pointerEvents: 'none', border: 'none' }}
            src={`https://free.timeanddate.com/countdown/i91qjg3z/n1398/cf12/cm0/cu4/ct0/cs0/ca0/co0/cr0/ss0/cac9a6fc7/cpc0ff/pct/tcfff/fs120/szw448/szh189/iso${config.COUNTDOWNCLOCK}T14:00:00/pa5`}
        />
    );
};

export default CountDownClock;
