import React from 'react';
import { Container, Header } from 'semantic-ui-react';

const IntraView: React.FC = () => {
    return (
        <>
            <Container text>
                <Header as="h2">Tammilanit Intra</Header>
                TODO
                <fieldset>
                    <legend>Sisällys</legend>
                    <ul>
                        <li>
                            <a href="#t">...</a>
                        </li>
                    </ul>
                </fieldset>
                <Header as="h3" id="t">
                    ...
                </Header>
                <p style={{ color: 'whitesmoke' }}>Ähäkutti, ei täällä ole mitään...</p>
            </Container>
        </>
    );
};

export default IntraView;
