import React, { FC } from 'react';
import Subtitle from 'components/Subtitle';

const SleepingInfo: FC = () => {
    return (
        <>
            <Subtitle title="Varaudu yövarusteilla!" />
            <p>Laneilla on runsaat lattiatilat yöpymiselle. Oma patja ja muut yöpymistarvikkeet mukaan.</p>
            <p>Pari suihkuakin on käytettävissä.</p>
        </>
    );
};

export default SleepingInfo;
