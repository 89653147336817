import React, { FC, useEffect, useState } from "react";
import TableGroup from "./TableGroup";
import AuthBar from "./AuthBar";
import tableService from "services/tableService";
import registrationService from "services/registrationService";
import "assets/app.scss";
import { Grid, Message } from "semantic-ui-react";
import config from "config/config";

const Content: FC = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [booked, setBooked] = useState([]);
  const [booking, setBooking] = useState("");
  const [loading, setLoading] = useState(true);
  const [registered, setRegistered] = useState<number>();

  useEffect(() => {
    loadBooked();
    loadRegistered();
  }, []);

  const setBookingLocation = (location: string) => {
    setBooking(location);
  };

  const loadBooked = () => {
    tableService
      .allBooked()
      .then(res => {
        setBooked(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadRegistered = () => {
    registrationService
      .count()
      .then(res => {
        setRegistered(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const setAuth = (authEmail: string, authCode: string) => {
    setEmail(authEmail);
    setCode(authCode);
  };

  const toggle = (location: string) => {
    if (booking === location) {
      tableService
        .free(email, code)
        .then(() => {
          setBookingLocation("-");
          loadBooked();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      tableService
        .book(email, code, location)
        .then(() => {
          setBookingLocation(location);
          loadBooked();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const noteForNoTables = (freeAmount: number) => (
    <>
      {freeAmount === 0 && (
        <Message color="blue">
          <Message.Header>HUOM! Ilmoittautuneita on enemmän kuin tässä näkyviä pöytäpaikkoja.</Message.Header>
        </Message>
      )}
    </>
  );

  const total = 63;
  const reserved = booked.length;
  const free = total - reserved;

  return (
    <div style={{ color: "whitesmoke" }}>
      {config.REGISTRATION_OPEN === "true" && (
        <>
          {loading ? (
            <Message color="blue">(odota hetki ladataan tietoja...)</Message>
          ) : (
            <div>
              {noteForNoTables(free)}
              <div style={{ marginTop: 10 }}>
                                <span style={{ marginBottom: 10 }}>
                                    Kirjoita sähköpostisi ja saamasi koodi, sen jälkeen varata vapaana olevan pöydän
                                    itsellesi.
                                    <br />
                                    Varauksen voi vaihtaa klikkaamalla toista pöytää tai poistaa klikkaamalla varattua
                                    pöytää.
                                    <br />
                                    Voit varata vain yhden pöydän.
                                </span>
                <AuthBar setBooking={setBooking} setAuth={setAuth} booking={booking} />
                <div style={{ marginBottom: 15, color: "#c6c6c6" }}>
                  Vapaana paikkoja:{" "}
                  <span>
                                        {free}/{total} (varattuja: {reserved})
                                    </span>
                </div>
                <div style={{ marginBottom: 15, color: "#a6a6a6" }}>
                  Ilmoittautuneita: <span>{registered}</span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className="table-clearfix">
        <h4>Värikoodit</h4>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <div className="table-legend table-free"></div>
              <span style={{display: 'inline-block'}}>Vapaa</span>
            </Grid.Column>
            <Grid.Column width={4}>
              <div className="table-legend table-premium"></div>
              <span style={{display: 'inline-block'}}>Vapaa Siunattu paikka</span>
            </Grid.Column>
            <Grid.Column width={4}>
              <div className="table-legend table-booking"></div>
              <span style={{ display: "inline-block" }}>Sinun pöytäsi</span>
            </Grid.Column>
            <Grid.Column width={4}>
              <div className="table-legend table-booked"></div>
              <span style={{display: 'inline-block'}}>Varattu</span>
            </Grid.Column>
            {/*<Grid.Column width={3}>
              <div className="table-legend table-disabled"></div>
              Vapautetaan tarvittaessa
            </Grid.Column>*/}
          </Grid.Row>
        </Grid>
      </div>
      <div className="table-clearfix table-scroller">
        <div className="table-layout">
          <div className={"table-group-area"} style={{ float: "left", top: "555px", left: "140px" }}>
            <TableGroup name="A" booked={booked} booking={booking} toggle={toggle} groupSize={10}
                        style={{ float: "left", top: "0px", left: "0px" }} />
            <TableGroup name="B" booked={booked} booking={booking} toggle={toggle} groupSize={10}
                        style={{ float: "left", top: "0px", left: "100px" }} />
            <div className={"table-group-spacer-small"}></div>
            <TableGroup
              name="C"
              booked={booked}
              booking={booking}
              toggle={toggle}
              groupSize={16}
              groupType={1}
              style={{ float: "left", top: "0", left: "70px" }}
            />
            <br />
            <TableGroup
              name="D"
              booked={booked}
              booking={booking}
              toggle={toggle}
              groupSize={62}
              style={{ float: "left", top: "-120px", left: "700px" }}
            />
            <TableGroup name="E" booked={booked} booking={booking} toggle={toggle} groupSize={4} groupType={1}
                        style={{ float: "left", top: "-40px", left: "-23px" }} />
            <TableGroup name="E" booked={booked} booking={booking} toggle={toggle} groupSize={4} groupType={1}
                        style={{ float: "left", top: "-40px", left: "12px" }} startOffset={4} />
            <TableGroup
              name="F"
              booked={booked}
              booking={booking}
              toggle={toggle}
              groupSize={42}
              style={{ float: "left", top: "-110px", left: "288px" }}
            />
            <TableGroup
              name="F"
              startOffset={4}
              booked={booked}
              booking={booking}
              toggle={toggle}
              groupSize={42}
              style={{ float: "left", top: "-110px", left: "329px" }}
            />
            <TableGroup name="G" booked={booked} booking={booking} toggle={toggle} groupSize={5} groupType={1}
                        rotate={true} style={{ float: "left", top: "-1280px", left: "-630px" }} />
          </div>
          <div className="table-food-table">Ruokapöytä</div>
        </div>
      </div>
    </div>
  );
};

export default Content;
