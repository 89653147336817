import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';
import Subtitle from 'components/Subtitle';
import FieldText from './FieldText';
import FieldTextArea from './FieldTextArea';

const Feedback: FC = () => {
    return (
        <>
            <Subtitle title="Palaute" />
            <p>Vapaa sana, toivomuksia tuleviin laneihin, palautetta Tammilaneista 2024</p>
            <Grid columns={1} stretched stackable>
                <Grid.Column>
                    <FieldTextArea name="feedback" title="Palaute" />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default Feedback;
