import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Menu } from 'semantic-ui-react';

const MainMenu: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const onClick = (path: string) => {
        console.log(path);
        navigate(path);
    };

    return (
        <>
            <Container>
                <Menu stackable pointing size="large" widths="6">
                    <Menu.Item name="Etusivu" active={location.pathname === '/'} onClick={() => onClick('/')} />
                    <Menu.Item name="Info" active={location.pathname === '/info'} onClick={() => onClick('/info')} />
                    <Menu.Item
                        name="Ilmoittautuminen"
                        active={location.pathname === '/registration'}
                        onClick={() => onClick('/registration')}
                    />
                    <Menu.Item
                        name="P&ouml;yt&auml;varaus"
                        active={location.pathname === '/booking'}
                        onClick={() => onClick('/booking')}
                    />
                    <Menu.Item
                        name="Intra"
                        active={location.pathname === '/intra'}
                        position="right"
                        onClick={() => onClick('/intra')}
                    />
                </Menu>
            </Container>
        </>
    );
};

export default MainMenu;
