// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header,
footer {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #333;
}

.required {
  color: #bd4a33 !important;
}

.errorMessage {
  margin-top: 10px;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/assets/Form.scss"],"names":[],"mappings":"AAEA;;EAEI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AADJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,mBAAA;AAAJ","sourcesContent":["@import 'assets/variables';\n\nheader,\nfooter {\n    width: 100%;\n    height: 50px;\n    line-height: 50px;\n    background: #333;\n}\n.required {\n    color: $error-color !important;\n}\n\n.errorMessage {\n    margin-top: 10px;\n    margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
