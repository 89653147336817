import React, { FC } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import Subtitle from 'components/Subtitle';
import FieldCheckbox from './FieldCheckbox';
import FieldText from './FieldText';
import { RegistrationDto } from 'types/common';
import { useFormikContext } from 'formik';

const Food: FC = () => {
    const { values } = useFormikContext<RegistrationDto>();

    return (
        <>
            <Subtitle title="Ruoka" />
            <p>Ruokaa on tarjolla seuraavasti:</p>
            <ul>
                <li>pe pikkupurtavaa</li>
                <li>la pikkupurtavaa sekä amatimiehen tekemä päivällinen n. klo 20</li>
                <li>su pikkupurtavaa</li>
            </ul>
            <p>
                Edellä mainittu ruokatarjoilu on lanittajille ilmainen. Lisäksi järjestetään isompia omakustanteisia
                yhteistilauksia lähiravintoloihin.
            </p>
            <Header as="h4">Ruokavalio</Header>
            <Grid columns={2} stretched stackable>
                <Grid.Column width="16">
                    <FieldCheckbox name="ruokaKaikki" label="Kaikki käy" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="ruokaLaktoositon" label="Laktoositon" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="ruokaGluteeniton" label="Gluteeniton" />
                </Grid.Column>
                <Grid.Column width="2">
                    <FieldCheckbox name="ruokaMuu" label="Muu: " />
                </Grid.Column>
                <Grid.Column width="14">
                    <FieldText
                        name="ruokaMuuKommentti"
                        title=""
                        placeholder="Muuta"
                        size="mini"
                        style={{ width: 250 }}
                        disabled={!values.ruokaMuu}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default Food;
