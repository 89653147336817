declare global {
    interface Config {
        API_BASE_URL: string;
        STAGE: string;
        REGISTRATION_OPEN: string;
        EVENT_TIME: string;
        COUNTDOWNCLOCK: string;
    }
}

const dev: Config = {
    API_BASE_URL: 'https://tammilan.it/api',
    STAGE: process.env.REACT_APP_STAGE ?? 'test',
    REGISTRATION_OPEN: 'true',
    EVENT_TIME: '17.-19.01.2025',
    COUNTDOWNCLOCK: '2025-01-17'
};

const prod: Config = {
    API_BASE_URL: process.env.REACT_APP_API_URL ?? 'https://tammilan.it/api',
    STAGE: process.env.REACT_APP_STAGE ?? 'production',
    REGISTRATION_OPEN: process.env.REACT_APP_REGISTRATION_OPEN ?? 'true',
    EVENT_TIME: process.env.REACT_APP_EVENT_TIME ?? '',
    COUNTDOWNCLOCK: process.env.REACT_APP_COUNTDOWNCLOCK ?? '2025-01-17'
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

console.log(config);

export default {
    ...config
};
