import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import Subtitle from './Subtitle';

const RegistryPolicy: React.FC = () => {
    return (
        <>
            <Container text>
                <Segment color="orange">
                    <Subtitle size="big" title="Rekisteriseloste" />
                    <p>Päivitetty: 6.7.2021</p>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="1. Rekisterinpitäjä" />
                    <p>Hyvinkään Helluntaiseurakunta</p>
                    <p>
                        Palo-ojantie 1
                        <br />
                        05810 Hyvinkää
                        <br />
                        Y-tunnus: 2923493-5
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="2. Rekisteriasiat" />
                    <p>Tammilanit tapahtumaan liittyen:</p>
                    <p>
                        Tapahtuman koordinaattori
                        <br />
                        Pekka Peltomäki
                        <br />
                        +358 40 081 1178
                        <br />
                        pekkapeltomaki (at) msn.com
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="3. Rekisterin nimi" />
                    <p>Rekisterin nimi: Tammilanit pelitapahtuman ilmoittautumis- ja mainontarekisteri</p>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="4. Rekisterin pitämisen peruste ja käyttötarkoitus" />
                    <p>
                        Tammilanit-yheitsö järjestää kaveri- ja tuttavaporukalleen Tammilanit -verkkopelitapahtumaa.
                        Tapahtumaan mahtuu rajallinen määrä osallistujia, siksi tarvitaan ilmoittaumiseen rekisteri.
                        Samaa rekisteriä käytetään myös osallistujille informoimiseen tulevista pelitapahtumista. Tai
                        muista heille kohdennetuista tapahtumista ja tilaisuuksista.
                    </p>
                    <p>
                        Yksittäiseen tapahtumaan tarvitaan laajemmin tietoa mm. ruokailutiedot ja mitä laitteita tuo
                        mukanaan. Tapahtumaan liittyvää tietoa ei säilytetä tapahtuman päättymisen jälkeen.
                    </p>
                    <p>
                        Alle 13-vuotiaiden osallistujien osalta kerätään huoltajan yhteystiedot mahdollisia
                        yhteydenottotarpeita varten. Huoltajien tietoja ei käytetä markkinointitarkoituksiin,
                        tapahtumaan liittyvistä asioista voidaan huoltajaa tiedottaa.
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="5. Rekisterin sisältämät tiedot" />
                    <p>Rekisteriin tallennetaan pysyvästi seuraavat tiedot osallistujista:</p>
                    <ul>
                        <li>sukunimi ja etunimi</li>
                        <li>puhelinnumero</li>
                        <li>sähköpostiosoite</li>
                        <li>huoltajan nimi, jos alaikäinen ilmoittautuja</li>
                        <li>huoltajan puhelinnumero, jos alaikäinen ilmoittautuja</li>
                    </ul>
                    <p>Yksittäiseen tapahtumaan liittyvää tietoa tallennetaan seuraavasti:</p>
                    <ul>
                        <li>mukaan tuotava laitteisto pöytävarausta varten</li>
                        <li>osallistumispäivät</li>
                        <li>kiinnostus osallistua yhteisiin turnauksiin</li>
                        <li>osallistuminen maksulliseen ruokailuun</li>
                        <li>ruoka-aine allergiat, mikäli osallistuu ruokailuun</li>
                    </ul>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="6. Säännönmukaiset tietolähteet" />
                    <p>
                        Henkilöiden tietoja kerätään vain ilmoittautumislomakkeen kautta, jonka osallistuja itse täyttää
                        ilmoittautuessaan tapahtumaan mukaan.
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="7. Tietojen luovutus" />
                    <p>Tietoja ei luovuteta eteenpäin kolmansille osapuolille.</p>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="8. Rekisterin suojauksen periaatteet" />
                    <p>
                        Kaikilla, joilla on oikeus lukea ja/tai muokata rekisteriä, on vaitiolovelvollisuus rekisterin
                        sisällöstä.
                    </p>
                    <p>
                        Teknisesti tiedot ovat salatussa tietokannassa, johon on pääsy vain DataCodex Oy:n teknisillä
                        toimihenkilöillä tapahtumakoordinaattorilla. Tapahtuman ilmoittautumissivuston kautta tietoja
                        käsitellään vain suojatun yhteyden kautta.
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="9. Rekisteritietojen tarkastusoikeus ja tarkastusoikeuden toteuttaminen" />
                    <p>
                        Jokaisella rekisteröidyllä on oikeus saada tarkastaa itseään koskevat tiedot. Tarkastusoikeus on
                        maksuton enintään kerran vuodessa (12 kk) toteutettuna. Rekisteröidyllä on henkilötietolain
                        perusteella tarkastusoikeus hänestä rekisteriin tallennettuihin tietoihin pyytämällä tietojensa
                        tarkastamista omakätisesti allekirjoitetulla asiakirjalla tai henkilökohtaisesti
                        rekisterinpitäjän luona. Rekisterinpitäjän yhteystiedot löytyvät tämän selosteen kohdasta 1 ja
                        2.
                    </p>
                </Segment>
                <Segment color="orange">
                    <Subtitle title="10. Henkilötietojen korjaaminen ja poistaminen rekisteristä" />
                    <p>
                        Rekisteröidyllä on oikeus vaatia virheellisten, puutteellisten, tarpeettomien taikka
                        vanhentuneiden tietojensa korjaamista tai poistamista ilmoittamalla asiasta rekisterinpitäjälle
                        tämän selosteen kohdissa 1 ja 2 mainittuun osoitteeseen. Käyttäjän tiedot voidaan poistaa
                        rekisteristä käyttäjän pyynnöstä.
                    </p>
                </Segment>
            </Container>
        </>
    );
};

export default RegistryPolicy;
